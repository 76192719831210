import {
  container,
  title,
  main,
  mainRaised,
  mrAuto,
  blackColor,
  mlAuto,
  description
} from "assets/jss/material-kit-pro-react.jsx";

const aboutUsStyle = {
  main,
  mainRaised,
  mrAuto,
  mlAuto,
  description,
  container: {
    ...container,
    zIndex: 1
  },
  title: {
    ...title,
    "&, & + h4": {
      color: blackColor
    }
  },
  textCenter: {
    textAlign: `center`
  },
  textLeft: {
    textAlign: `left`,
    marginLeft: '0%'
  },
  block: {
    color: `inherit`,
    padding: `0.9375rem`,
    fontWeight: `500`,
    fontSize: `12px`,
    textTransform: `uppercase`,
    borderRadius: `3px`,
    textDecoration: `none`,
    position: `relative`,
    display: `block`
  },
  inlineBlock: {
    display: `inline-block`,
    padding: `0px`,
    width: `auto`
  },
  list: {
    marginBottom: `0`,
    padding: `0`,
    marginTop: `0`
  },
  left: {
    float: `left!important`,
    display: `block`
  },
  right: {
    padding: `15px 0`,
    margin: `0`,
    float: `right`
  },
  icon: {
    width: `18px`,
    height: `18px`,
    top: `3px`,
    position: `relative`
  },
  noShadow: {
    boxShadow: `none !important`,
    border: `1px solid !important`,
    borderColor: `#eee !important`
  }
};

export default aboutUsStyle;
