import React from "react";
import { graphql } from "gatsby";
// nodejs library that concatenates classes
import classNames from "classnames";
import PropTypes from "prop-types";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Accordion from "components/Accordion/Accordion.jsx";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import downloadsStyle from "ps-assets/jss/downloadsStyle.jsx";
import Layout from "ps-components/Layout/Layout.jsx";
import { Helmet } from "react-helmet";
import Markdown from "react-markdown";
import Table from "components/Table/Table";

const FAQs = ({ classes, data }) => {
  var Images = data.allStrapiImages.edges;
  var clrImage = Images[1];
  var Alldata = data.allStrapiEntities.edges;

  let AccordionArray = Alldata.map(category => {
    return {
      title: category.node.title,
      content: (
        <>
          <Markdown source={category.node.description} />
          {category.node.table && (
            <Table
              tableHead={category.node.table.tablerows[0].tablecolumns.map(
                col => {
                  console.log(`det ${col.details}`);
                  return col.details;
                }
              )}
              tableData={category.node.table.tablerows.slice(1).map(row => {
                return row.tablecolumns.map(col => {
                  return col.details;
                });
              })}
            />
          )}
        </>
      )
    };
  });

  return (
    <Layout
      image2={
        clrImage &&
        clrImage.node &&
        clrImage.node.content &&
        clrImage.node.content.childImageSharp.fluid
      }
      small={true}
      title={``}
      summary={``}
    >
      <Helmet key="helmetTags">
        <title> Faqs | StarHealth.in</title>
      </Helmet>
      {process.env.ENABLE_HELMET == `true` && (
        <Helmet key="helmetTags">
          <meta name="title" content="Faqs | StarHealth.in" />
          <meta name="twitter:title" content="Faqs | StarHealth.in" />
          <meta
            name="description"
            content="FAQS - If you have any queries which are related to health Insurance policy, please get your doubts clarified here."
          />
          <link rel="canonical" href="https://www.starhealth.in/faq" />

          <meta
            name="keywords"
            content="Buy Online Health Insurance Plans, Health Insurance, Accident Care, Overseas Travel, Insurance Policy, Insurance Agent Corner, Star Health and Allied Insurance Company Limited, Chennai, India, Portability, Star Health Products, Renew Policy Online, Health Insurance Specialist In India"
          />
          <meta property="og:title" content="Faqs | StarHealth.in" />
          <meta property="og:description" content="FAQS - If you have any queries which are related to health Insurance policy, please get your doubts clarified here." />
          <meta property="og:url" content="https://www.starhealth.in/faq"/>
          <meta name="Faqs | StarHealth.i" />
          <meta name="twitter:description" content="FAQS - If you have any queries which are related to health Insurance policy, please get your doubts clarified here." />
          <meta property="twitter:url" content="https://www.starhealth.in/faq" />
        </Helmet>
      )}
      <div
        className={classNames(classes.main, classes.mainRaised)}
        style={{ marginBottom: 100 }}
      >
        <div className={classes.section}>
          <div className={classes.container}>
            <GridContainer
              className={`${classes.container} ${classes.textCenter}`}
            >
              <GridItem
                md={12}
                className={`${classes.mrAuto} ${classes.mlAuto} ${classes.textCenter}`}
              ></GridItem>
              <GridItem
                className={`${classes.mrAuto} ${classes.mlAuto} ${classes.textCenter}`}
                xs={12}
                sm={12}
                md={10}
                style={{ marginBottom: 50, paddingTop: 70 }}
              >
                <Accordion active={0} collapses={AccordionArray} />
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    </Layout>
  );
};
FAQs.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object
};
export default withStyles(downloadsStyle)(FAQs);

FAQs.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object
};

export const downloadsQuery = graphql`
  query FAQs {
    allStrapiEntities(filter: { category: { eq: "faq" } }) {
      edges {
        node {
          id
          category
          title
          description
          table {
            tablerows {
              tablecolumns {
                details
              }
            }
          }
        }
      }
    }
    allStrapiImages(filter: { category: { eq: "faqs" } }) {
      edges {
        node {
          content {
            publicURL
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;
